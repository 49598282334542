<template>
  <v-dialog v-model="dialog" max-width="1600" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="white--text"
        color="xbColor"
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent">
        Nova Categoria
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('update:dialogUsersAClassificarAli', false)">
          mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text class="px-5 py-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row dense class="px-4">
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.descricao"
                label="Descrição"
                :rules="formRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn class="xbColor--text" @click="dialog = false"> Cancelar </v-btn>
        <v-btn class="white--text" color="xbColor" @click="createCategoria">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createCategoria } from "@/api/admin/loja";
import { inputRequired } from "@/plugins/utils.js";

export default {
  name: "NovaCategoria",

  props: {
    update: {
      type: Function,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      salvando: false,
      indexSalvando: null,
      form: {},
      formRules: [inputRequired],
      validForm: true,
    };
  },

  methods: {
    createCategoria() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        createCategoria(this.form.descricao)
          .then(() => {
            this.$toast.success("Categoria criada com sucesso");
            this.loading = false;
            this.dialog = false;
            this.update();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {},
};
</script>

<style></style>
